<template>
    <div @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>变更记录</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>

                        <a-form-item>
                            <a-radio-group :value="searchParams.search.client_type">
                                <a-radio-button @click="handleTypeButChange('leads','client_type')" value="leads">
                                    线索
                                </a-radio-button>
                                <a-radio-button @click="handleTypeButChange('customer','client_type')" value="customer">
                                    客户
                                </a-radio-button>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item >
                            <a-range-picker
                            style="width: 240px" 
                            :ranges="rangesData"
                            v-model='searchDatas.distribute'
                            allowClear
                            @change="(val,time)=>handleTime(val,time,'distribute')" />
                        </a-form-item>

                        <!-- <a-form-item>
                            <a-input v-model='searchParams.search.keyword'  style="width: 160px" allowClear placeholder="名称/手机号"></a-input>
                        </a-form-item> -->
                        <!-- <a-form-item>
                            <a-select v-model='searchParams.search.studio_id' style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item> -->
                        
                        <a-form-item>
                            <a-select style="width: 160px" mode="multiple" v-model='searchParams.search.action_user_id' allowClear showSearch placeholder="请选择操作账户" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>

                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                                <template slot="title">
                                <span>操作内容关键词</span>
                                </template>
                                <a-input allowClear v-model.trim="searchParams.search.fuzzy" placeholder="操作内容关键词" style="width: 160px"/>
                            </a-tooltip>
                        </a-form-item>

                        <a-form-item>
                            <a-button @click="searchList" html-type="submit" :loading="loading" type="primary">搜索</a-button>
                        </a-form-item>

                        <!-- <a-form-item>
                            <a-popover placement="bottom" trigger="click" v-model="visible">
                            <template slot="content">
                                <div class="more-search">
                                <a-form layout='inline'>
                                    <a-form-item class="block-line" label="操作账户">
                                        <a-select mode="multiple" v-model='searchParams.search.assigned_by' allowClear showSearch placeholder="请选择操作账户" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="分配时间">
                                            <a-range-picker
                                            :ranges="rangesData"
                                            v-model='searchDatas.distribute'
                                            allowClear
                                            @change="(val,time)=>handleTime(val,time,'distribute')" />
                                    </a-form-item>
                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button type="primary" @click="searchList">搜索</a-button>
                                </div>
                                </div>
                            </template>
                            <a-badge>
                                <a-button>更多<a-icon type="down" /></a-button>
                            </a-badge>
                            </a-popover>
                        </a-form-item> -->
                    </a-form>
                </div>
            </div>
            <div @click.stop class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="id"
                    :columns="columns" :dataSource="list" :customRow="clickRow" :rowClassName="rowClassName">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="client_type" slot-scope="text">
                        <span>{{text == 'customer' ? '客户':'线索'}}</span>
                    </template>
                    <template slot="action_attributes" slot-scope="text">
                        <div v-html="text"></div>
                    </template>
                    <template slot="name" slot-scope="text,record">
                        <span>{{record.client_type == 'customer' ? record.customer_detail.customer_name:record.leads_detail.clue_name}}</span>
                    </template>

                    <template slot="cellphone" slot-scope="text,record">
                        <span>{{record.client_type == 'customer' ? record.customer_detail.cellphone:record.leads_detail.cellphone}}</span>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <followModal :authType="['crm','leads']" :item="modalData" :leadsId='leadsId' :isTab='isTab' v-if="followVisible"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed: 'left'},
  {title: '操作账户', dataIndex: 'created_by',key: 'created_by'},
  {title: '联系名称', dataIndex: 'name',key: 'name', scopedSlots: { customRender: 'name' }},
  {title: '联系电话', dataIndex: 'cellphone',key: 'cellphone', scopedSlots: { customRender: 'cellphone' }},
  {title: '类型', dataIndex: 'client_type',key: 'client_type', scopedSlots: { customRender: 'client_type' }},
  {title: '变更内容', dataIndex: 'action_attributes',key: 'action_attributes' , scopedSlots: { customRender: 'action_attributes' }},
  {title: '变更时间', dataIndex: 'created_at',key: 'created_at'},
]
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    import tableMixins from '@/common/mixins/table'
    import followModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:"transfer",
        data() {
            return {
                columns,
                loading: false,
                exportLoading: false,
                visible: false,
                followVisible: false,
                list: [],
                studios: [],
                staffList: [],
                consultants: [],
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0
                    },
                    "sort": ''
                },
                searchDatas: {},
                modalData:{},
                leadsId:'',
                clickId:'',
                isTab:'0',
            }
        },
        mixins:[tableMixins,ranges],
        components: {
            followModal,
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.getStudio()
            this.getConsultant()
            this.getStaff()
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                await this.$store.dispatch('cChangeLogAction',{data:this.searchParams})
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getConsultant() {
                let res = await this.$store.dispatch('searchConsultantAction', {})
                this.consultants = res.data
            },
            async getStaff(){
                let res = await this.$store.dispatch('searchAccountEmployeeAction')
                this.staffList = res.data
            },
            handleTypeButChange(e,name){
              if(this.searchParams.search[name] == e){
                this.searchParams.search[name] = undefined
              }else{
                this.searchParams.search[name] = e
              }
              this.getList()
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
                this.changeBadge()
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                let start_date = 'start_date'
                let end_date = 'end_date'
                this.searchParams.search[start_date] = time[0]
                this.searchParams.search[end_date] = time[1]
            },
            reset() {
                this.searchDatas = {}
                this.searchParams.search = {is_sub:0}
            },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            this.clickId = record.id
                            this.showFollowModal(null, record)
                        }
                    }
                }
            },
            rowClassName(record, index) {
                let className = "";
                if (record.id == this.clickId) {
                    className = "rowActive";
                    this.$emit('change', this.clickId)
                }
                return className;
            },
            showFollowModal(e, item) {
                e && e.stopPropagation()
                if(item.client_type === 'leads'){
                    if(item.leads_detail.customer_id){
                        this.isTab = '1'
                        this.modalData = item.leads_detail
                    }else{
                        this.isTab = '0'
                        this.modalData = item.leads_detail
                    }
                }else{
                    this.isTab = '1'
                    this.modalData = item.customer_detail
                }
                this.leadsId = item.id
                this.followVisible = true
                return 
            },
            hideFollowModal(type) {
                if (type === 1) {
                    this.getList()
                }
                this.followVisible = false
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                }
                let res = await this.$store.dispatch('cChangeLogExportAction',exportData)
                if (res.type.indexOf('json') !== -1) {
                    var blob = new Blob([res], {
                        type: 'application/json'
                    })
                    let reader = new FileReader();
                    reader.readAsText(blob, 'utf-8')
                    reader.onload = () => {
                        var temp = JSON.parse(reader.result)
                        console.log(temp);
                        if(temp.status=='error'){
                            this.$message.error(temp.msg);
                            this.exportLoading = false
                            return false;
                        }
                    }
                }else{
                    const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                    if ('download' in document.createElement('a')) { 
                        const link = document.createElement('a')
                        link.download = `变更记录导出.${moment().format('YYYY.MM.DD')}.xlsx`
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        document.body.appendChild(link)
                        link.click()
                        URL.revokeObjectURL(link.href) 
                        document.body.removeChild(link)
                    } else { //其他浏览器
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss">
    .rowActive{
        background:#e6fff6
    }
</style>